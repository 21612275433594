<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcGideonCardNotes }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-header>
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>{{ translations.tcNotes }}</h2>
            <ToggleArrow
              v-if="toggle == true"
              @click="Section1 = !Section1"
              section="Section1"
              :status="Section1"
            ></ToggleArrow>
          </header>
          <b-button
            v-if="Section1 && iCanSeeAny(secured_note_add_controls)"
            variant="primary"
            @click="visitStoreAndRedirect({})"
            >{{ translations.tcAddNote }}</b-button
          >
          <b-button
            v-if="Section1 && convertedNotes.length > 6"
            class="ml-3"
            variant="primary"
            @click="showAll = !showAll"
          >
            <span v-if="!showAll">{{ translations.tcViewAllNotes }}</span>
            <span v-if="showAll">{{ translations.tcHideExtraNotes }}</span>
          </b-button>
          <div v-if="Section1 && translations.components" class="pres-dates d-flex flex-wrap">
            <div class="col col-4 mb-3" v-for="(note, index) in this.convertedNotes" :key="note.nte_key">
              <app-event
                :obj="note"
                :i18n="translations.components"
                v-if="index < 6 || showAll"
                @edit_requested="onEditClick($event)"
              ></app-event>
            </div>
          </div>
        </div>
      </section>
    </page-body>
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpGideonCards.vue'
import date_data from '@/json/date.json'
import Event from '@/components/Event.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_notes',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      showAll: false,
      Section1: true,
      Section2: true,
      backJumpObj: {
        link: '#',
        location: '',
      },
      secured_note_add_controls: {
        add_gideoncard_facility_note_button: '390e417b-86d2-40e8-8aae-0696e23cbe47',
      },
      secured_note_delete_controls: {
        delete_gideoncard_facility_note_button: 'f2cf8b0c-3153-4426-8291-7f2c7f2af501',
      },
      secured_note_edit_controls: {
        edit_gideoncard_facility_note_button: 'a59c2daa-40fb-480b-a476-e6addd6032fc',
      },
    }
  },
  methods: {
    ...mapActions({
      loadNotes: 'card/getFacilityNotes',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedNote: 'user/setSelectedNote',
    }),
    async pageLoad() {
      await Promise.all([this.setLoadingStatus(true), await this.loadNotes(this.userSelectedFacilityKey)]).then(() => {
        this.backJumpObj.location = this.location.org_name
        this.backJumpObj.link = '/programs/gc/profile/summary/card_profile'
        this.setLoadingStatus(false)
      })
    },
    async visitStoreAndRedirect(data) {
      this.setSelectedNote(data)
      this.$router.push({ name: 'card_facility_edit_note' })
    },
    onEditClick(data) {
      this.setSelectedNote(data)
      this.$router.push({ name: 'card_facility_note_details' })
    },
    async handleDeleteRequest(note) {
      let data = {
        nte_key: note.nte_key,
        contact_date: note.contact_date,
        contact_by_ind_key: note.nte_contact_by_ind_key,
        name: note.name,
        ctt_key: note.nte_ctt_key,
        note: note.nte_note,
        member_key: this.userId,
        org_key: this.userSelectedChurchKey,
        delete: true,
      }
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertNote(data)
        this.setLoadingStatus(false)
        if (response !== '') {
          await this.pageLoad()
          this.$swal({
            icon: 'success',
            confirmButtonText: this.translations.tcOk,
            text: this.translations.tcNoteDeletedSuccessfully,
          })
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcErrorDeletingNote,
        })
      }
    },
    async pageLoad() {
      await Promise.all([this.setLoadingStatus(true), await this.loadNotes(this.userSelectedFacilityKey)]).then(() => {
        this.backJumpObj.location = this.location.org_name
        this.backJumpObj.link = `/programs/gc/profile/summary/card_profile`
        this.setLoadingStatus(false)
      })
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      location: 'card/getFacilitySummary',
      notes: 'card/getNotes',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
    }),
    months() {
      return date_data.months.map((dm) => dm.abbrlow)
    },
    convertedNotes() {
      return this.notes.map((obj) => {
        let cDate = !!obj.contact_date ? new Date(obj.contact_date) : null
        let month = !!cDate ? this.months[cDate.getMonth()] : ''
        let day = !!cDate ? cDate.getDate() : ''
        let year = !!cDate ? cDate.getFullYear() : ''
        let fullDate = !!obj.contact_date ? obj.contact_date : ''
        let rObj = {
          nte_key: obj.nte_key,
          contact_date: obj.contact_date,
          nte_ctt_key: obj.nte_ctt_key,
          contact_type: obj.contact_type,
          nte_contact_by_ind_key: obj.nte_contact_by_ind_key,
          name: obj.name,
          sort_name: obj.sort_name,
          nte_note: obj.nte_note,
          month: month,
          day: day,
          year: year,
          fullDate: fullDate,
          editLink: true,
        }

        return rObj
      })
    },
  },
  async created() {
    try {
      if (!this.userSelectedFacilityKey) {
        this.$router.push('/programs/gc/gideon-card')
      }
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('back-jump-gideon-cards', 'quick-jump-gideon-cards', 'event'),
        await this.pageLoad(),
      ]).then((results) => {
        const translatedText = { ...results[1] }
        this.$set(this.translations, 'components', translatedText)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    appEvent: Event,
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
.service-times-presentations {
  header h1 {
    padding-bottom: 40px;
    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }
  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;
    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  section {
    margin-bottom: 40px;
    .p-4 {
      padding: 40px 30px 20px 30px !important;
      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }
    header {
      display: flex;
      h2 {
        flex: 1 1 auto;
        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }
      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }
    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .service-times,
  .pres-dates {
    margin-top: 35px;
  }
  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;
      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.event {
  margin-bottom: 0;
  border: 1px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  h3 {
    color: $teal-800;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}
</style>
